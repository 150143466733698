.invoice-container {
  width: 800px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  padding: 20px;


}
.invoice_view{
  height: calc(100vh - 30px);
  overflow: scroll;
  background-color: #EBD3F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 33rem;
  /* width: 800px;
  margin: 0 auto; */
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  background-color: #adadad;
  padding: 20px;
}

.company-details {
  text-align: left;
}

.invoice-title {
  text-align: right;
}

.invoice-body {
  margin-top: 20px;
}

.account-details,
.bill-to {
  width: 48%;
  display: inline-block;
  vertical-align: top;
}

.order-details {
  margin-top: 10px;
}

.start-date,
.ordered-by {
  width: 48%;
  display: inline-block;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.invoice-footer {
  margin-top: 20px;
  text-align: right;
}
.btn-invoice{
  background-color: #7A1CAC;
  padding: 9px 40px;
  margin-bottom: 3rem;
  border-radius: 10px;
  cursor: pointer;
}
.btn-invoice p{
  color: #fff;
  margin: 0px;
  cursor: pointer;
  /* padding: 9px 40px; */
}