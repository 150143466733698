.viewtable{
    background-color: #edd4fa;
    height: 100vh;
    padding: 1rem;
   
}
.search{
    /* margin-top: 15px;
    margin-left: 28px;
    border-radius: 5px;
    padding: 5px; */
    border-radius: 5px;
            padding: 5px;
            border:2px solid #7A1CAC;
   
}
.emptable{
    background-color: #FFF6EA;
    height: calc(100vh - 150px);
    margin-left: 25px; 
    margin-top: 15px;
    border-radius: 5px;
   overflow-x: scroll;
   margin-right: 20px;
}
.tableheader{
   background-color:#7A1CAC;
   border: 1px solid #4E73DE;
   border-left: none; 
   border-right: none;
   /* padding: 20px 30px 20px 30px; */
   gap: 10px;
   color:white;



  
}
.empdetailstable{
    margin-top: 10px;
    margin-left:10px ;
   
    
}
.tabledata{
    border: 1px  solid #4E73DE;
    border-left: none; 
    border-right: none;
    font-family: Nunito;
font-size: 16px;
font-weight: 700;
line-height: 24.55px;
text-align: center;
box-shadow: 0px 2px 0px 0px #4E73DE;
padding: 10px 30px 10px 30px;
gap: 10px;
}
.searchbaremptab{
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 20%;
    margin-left: 20px;
    height:"35px";
    border-Radius:5px;
    justify-Content:space-between;
    padding:"0px 10px"
}