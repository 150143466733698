.customerviewtablebg{
    
    background-color: rgb(206, 164, 245);
    height: 100vh;
    /* padding: 20px; */
    /* height: calc(100vh-120px); */
}
.customerviewtableinput{
    /* width: 250px;
    height: 44px;
    /* width: 246px;
height: 40px;
gap: 0px;
opacity: 0px; */

    /* top: 105px;
            
    left: 280px;
            padding: 2px 0px 0px 0px;
            gap: 10px;
            border: 2px solid #7A1CAC;
            margin-bottom: 10px;
            opacity: 0px; */ 
            /* position: fixed; */
            /* margin-top: 15px;
            margin-left: 28px; */
            border-radius: 5px;
            padding: 5px;
            border:2px solid #7A1CAC;
}
.customerviewincontainer{
    border-radius: 5px;
    height: calc(100vh - 150px);
          background-color:  #FFF6EA;
          margin-left: 25px;
          margin-top: 20px;
          overflow-x: scroll;
          
         
          /* border-radius: 9px;
          width: 3865px;
          height: 578px;
          top: 175px;
          left: 280px;
          gap: 0px;
          opacity: 0px; */
          /* overflow-x: scroll; */
          /* overflow-y: hidden;  */
          /* position: relative; */
          
}
/* .viewcustomerserach{
    width: 3269px;
height: 820px;
top: 175px;
left: 280px;
gap: 0px;
opacity: 0px;

} */
 /* .viewcustomerrow{
    border: 1px solid #4E73DE;
} */
 
.viewcustomerheader{
    
    
    border-bottom: 1px solid #7A1cac;
    background-color: #7A1CAC; 
    color: white;
    text-align: center;
    border-left: none;
    border-right: none;
    white-space: nowrap; 
    
   
   
    
}


.viewcustomerdata{
   
    border-bottom: 2px solid #4E73DE;
    border-left: none;
    border-right: none;
    text-align: center;
    white-space: nowrap; 
   
}


/* .customerviewsearch{
    width: Fixed (250px)px;
height: Hug (44px)px;
top: 105px;
left: 280px;
padding: 2px 0px 0px 0px;
gap: 10px;
border-radius: 5px 0px 0px 0px;
opacity: 0px;
background: #FFFFFF;

} */
 .customerviewtable{
    margin-top: 10px;
    margin-left: 10px;
    /* border: 1px solid black; */
    
    

 }
 .customerviewtablehead{
    border-left: none;
    border-right: none;
 }
 