.customercreationbg {
  height: calc(100vh - 85px);
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 20px;
  background: #fff6ea;
  border-radius: 9px;
  padding: 30px;
  margin: 20px;
}
.customercontainer {
  display: flex;
  flex-direction: row;
  width: 975px;
  height: 878.17px;
  gap: 175px;
}
.customercreationheader {
  flex-direction: column;
  width: 400px;
  height: 743px;
  gap: 20px;
  display: flex;
}
.custdate {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 400px;
  background-color: #fff6ea;
  border-radius: 9px;
  outline: none;
  border: 1px solid black;
}
.custinput {
  gap: 20px;
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 400px;
  background: #fff6ea;
  border-radius: 9px;
  outline: none;
  border: 1px solid black;
}
.custlabel {
  width: 400px;
  height: 17px;
  gap: 0px;
  /* opacity: 0px; */

  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.custominput {
  width: 400px;
  height: 95px;
  border-radius: 5px;
  /* border: 1px 0px 0px 0px; */
  background: #fff6ea;
  border-radius: 9px;
  outline: none;
}
.customdescription {
  width: 400px;
  height: 164px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  background: #fff6ea;
  border-radius: 9px;
  outline: none;
}
.custselect {
  gap: 20px;
  /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 400px;
  background: #fff6ea;
  border-radius: 9px;

  border: 2px solid #000;
}
.customercreationheaderright {
  width: 400px;
  height: 878.17px;
  flex-direction: column;
  gap: 20px;
  display: flex;
}
.customselectright {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.btn-group.dropend {
  width: 200px;
  position: relative;
}

.btn-custom {
  width: 100px;
  height: 50px;
  background-color: #7a1cac !important;
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
}

.viewCustomerMainSection,
.studentMainSection,
.customerMainSection {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(206, 164, 245);
}
.customerSearchBlock {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 38px;
  background: #ffffff;
  border: 2px solid #7a1cac;
  border-radius: 5px;
}
.search-box {
  outline: none;
  border: none;
}
.download-icon {
  width: 38px;
  height: 38px;
}
.image-icon {
  width: 22px;
  height: 22px;
}
.view-customer-heading-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.view-customer-table-container {
  border-radius: 5px;
  height: calc(100vh - 140px);
  background-color: #fff6ea;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.view-customer-table-head {
  background-color: #7a1cac;
  color: white;
  text-align: center;
  padding: 18px 0px;
  /* min-width: 140px !important; */
  /* font-family: Inter; */
  font-weight: 700;
  font-size: 16px;
  font-family: Nunito;
}
.view-customer-table-data {
  border-bottom: 2px solid #4e73de;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  /* min-width: 140px !important; */
  padding: 18px 0px;
  background-color: #fff6ea;
}
.view-customer-table-block {
  width: 98%;
  height: calc(100vh - 150px);
  /* border: 1px solid red; */
  overflow: auto;
  scrollbar-width: thin;
}
.view-customer-table-head_first {
  position: sticky;
  top: 0px;
  left: 0px;
}
.view-customer-table-data_first {
  position: sticky;
  top: 0px;
  left: 0px;
}
.view-customer-table-head_second {
  position: sticky;
  top: 0px;
  left: 140px;
}
.view-customer-table-data_second {
  position: sticky;
  top: 0px;
  left: 140px;
}
.view-customer-table-head_third {
  position: sticky;
  top: 0px;
  left: 280px;
}
.view-customer-table-data_third {
  position: sticky;
  top: 0px;
  left: 280px;
}
.view-customer-table-head_fourth {
  position: sticky;
  top: 0px;
  left: 420px;
}
.view-customer-table-data_fourth {
  position: sticky;
  top: 0px;
  left: 420px;
}

.studentAddMainContainer {
  background-color: #fff6ea;
  height: calc(100vh - 85px);
  border-radius: 8px;
  overflow: auto;
  scrollbar-width: thin;
}
