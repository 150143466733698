@import url(./employecreationForm.css);
@import url(./AddSales.css);
@import url(./StudentcreationForm.css);
@import url(./NewQuotation.css);
@import url(./Uploadpayslip.css);
@import url(./Downloadpayslip.css);
@import url(./CustomerCreationForm.css);
@import url(./Viewemployee.css);
@import url(./Viewsales.css);
/* @import url(./Viewcustomer.css); */
@import url(./Sidebar.css);
@import url(./ViewCustomerTable.css);

@import url(./login.css);
@import url(./App.css);
@import url(./Modal.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.commonStyle {
  display: flex;
  flex-direction: column;
  gap: 10;
}
.studentcreationbg {
  background: #ebd3f8;
}
