.Viewsales-bg{

  background-color: #edd4fa;
    overflow-x: scroll;
    height:100vh;
    width:50;
   /* overflow-y: hidden; */
 
}
/* thead{
    gap:0px;
    
     padding-left:30px; 
} */

.bg{
     width: 50;
    } 
.background{
    background: #FFF6EA;
    margin: 20px ;
    border-radius: 5px;
     width: 2630px; 
    height:  150vh;   
    overflow: hidden;
    height:864px;
            }
 th{
    padding:2px; 
    background-color:  #7A1CAC;
    color:#FFF6EA;
    margin-top:10px;
    margin:auto;
    height: 1px;
} 
 .tab{
     width:100%;
    flex-direction: row;
    justify-content: space-between; 
    padding-bottom:10px;
   
      } 
 .background,th{
    padding:20px; 
    padding:8px;
  
} 
tr{
    width: Fixed (400px)px;
    height: Hug (65px)px;
    padding: 20px 30px 20px 30px;
    font-family: Nunito;
    margin:20px;
    line-height: 1.5;
    text-align: center;
    padding-bottom:5px;
    padding-top:5px;
    /* padding-top: 10px;  */
  margin-top: 5px; 
    /* border-bottom: 1px solid #000; */
}
  .line{
    color:rgba(78,115,222,1);
    font-weight: bold;
    } 
     
 /* th,td{
    border:1px solid black;
    padding:5px;
    padding-right: 1px;
   } */
       
   
    /* .description-add{
    /* max-width: 100px;  */
     /* padding-top:15px;  */
     /* font-weight:bold; 
   }   */


   /* .des{
    width:290px; 
    height:50px;
    font-weight: lighter;
    padding-top:15px;
    max-width:120px; 
   } */
   .content{
    /* font-family: Nunito;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    text-align: center; */
    border: 2px  solid #4E73DE;
     border-left: none; 
    border-right: none; 
    border-top:none;
    font-family: Nunito;
font-size: 18px;
font-weight: 700;
line-height: 24px;
text-align: center;

/* box-shadow: 0px 2px 0px 0px #4E73DE; */

padding: 20px 30px 20px 30px;
gap: 10px;


    }
 /* .line{
    font-weight: bold;
    margin-top: -10px;
    border: 2px solid #4E73DE;
    width: 100%;
 } */
 .icon-pointer{
    cursor:pointer;
    color:white;
  
    
 }
 /* tbody{
   gap:0px;
 } */
  /* .name,.invoice,.company{
gap:3px;
padding-right:2px;
  } */
   .viewsales{
      margin-top: 10px;
      margin-left: 10px;
   }
   .header{
      background-color:#7A1CAC;
   border: 1px solid #4E73DE;
   border-left: none; 
   border-right: none;
   padding: 20px 20px 20px 20px;
   gap: 10px;
   color:white;
   
   }
  
